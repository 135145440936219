/* src/components/Header.module.css */

.header {
    position: sticky;
    top: 0;
    z-index: 50;
    background: white;
    border-bottom: 1px solid #e2e8f0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.headerContent {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo {
    font-size: 1.5rem;
    font-weight: 700;
    color: #4F46E5;
    text-decoration: none;
}

.navigation {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.navLink {
    color: #4b5563;
    text-decoration: none;
    font-weight: 500;
    font-size: 0.9rem;
    padding: 0.5rem;
    border-radius: 0.375rem;
    transition: all 0.2s ease;
}

.navLink:hover {
    color: #4F46E5;
    background: rgba(79, 70, 229, 0.1);
}

.navLink.active {
    color: #4F46E5;
    background: rgba(79, 70, 229, 0.1);
}

.headerActions {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.iconButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    color: #4b5563;
    transition: all 0.2s ease;
}

.iconButton:hover {
    background: rgba(79, 70, 229, 0.1);
    color: #4F46E5;
}

.signInButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background: #4F46E5;
    color: white;
    border: none;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.2s ease;
    position: relative;
}

.signInButton:hover {
    background: #4338CA;
}

.signInBadge {
    position: absolute;
    top: -6px;
    right: -6px;
}

.profileButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background: transparent;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    color: #4b5563;
    font-size: 0.875rem;
    text-decoration: none;
    transition: all 0.2s ease;
}

.profileButton:hover {
    background: #f9fafb;
    border-color: #d1d5db;
}

.userName {
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.menuButton {
    display: none;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
    color: #4b5563;
}

.menuButton svg {
    width: 1.5rem;
    height: 1.5rem;
}

/* Мобильная версия */
@media (max-width: 768px) {
    .navigation {
        display: none;
        position: fixed;
        top: 72px;
        left: 0;
        right: 0;
        background: white;
        padding: 1rem;
        flex-direction: column;
        align-items: stretch;
        gap: 0.5rem;
        border-top: 1px solid #e5e7eb;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    }

    .navigation.navigationOpen {
        display: flex;
    }

    .menuButton {
        display: flex;
    }

    .headerActions {
        gap: 0.5rem;
    }

    .signInButton {
        width: 40px;
        height: 40px;
        padding: 0;
        justify-content: center;
        border-radius: 50%;
    }

    .signInButton span {
        display: none;
    }

    .signInBadge {
        position: absolute;
        top: -4px;
        right: -4px;
    }

    .userName {
        display: none;
    }

    .profileButton {
        width: 40px;
        height: 40px;
        padding: 0;
        justify-content: center;
    }
}

/* Анимации */
@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.navigation.navigationOpen {
    animation: slideIn 0.2s ease-out;
}