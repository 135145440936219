body {
  margin: 0;
  font-family: 'Manrope', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  position: relative;
}

html {
  height: 100%;
  scroll-behavior: smooth;
  position: relative;
  scroll-behavior: smooth;

}

#root {
  min-height: 100%;
  position: relative;
  overflow-x: hidden;

}

code {
  font-family: "Manrope", Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  display: none;
}

* {
  scrollbar-width: none;
  -ms-overflow-style: none;
  box-sizing: border-box;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}