
/* oldcolors  
#4CAF50 -> #3b97e8

*/

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.productList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 24px;
    padding: 20px;
    background-color: #f8f9fa;
  }
  
  .productCard {
    display: flex;
    flex-direction: column;
    /* height: 700px; */
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
  }

.productInfo {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 5% 5% 0 5%;
  }

.productTitle {
    font-size: 18px;
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .productDescription {
    margin-bottom: 5px;
  }
  
  .featuresList {
    flex: 1;
    overflow-y: auto;
    margin-bottom: 5px;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .featuresList::-webkit-scrollbar {
    display: none;
  }
  .productFooter {
    margin-top: auto;
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
.productCard:hover {
    transform: scale(1.05);
  }
  
  .shoppingCart {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    padding: 24px;
    max-width: 400px;
    margin: 20px auto;
  }
  
  .cartItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #eee;
  }
  
  .cartSummary {
    text-align: center;
  }
  
  .addToCartButton:hover {
    background-color: #53a2e7;
  }

  .div_navigation {
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f9fa;
  }

  .navigation {
    display: flex;
    gap: 20px;
  }
  
  .navigation a {
    text-decoration: none;
    color: #333;
    transition: color 0.3s;
  }
  
  .navigation a:hover {
    color: #3b97e8;
  }
  
  .cartLink {
    position: relative;
  }
  
  .cartCount {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 4px 8px;
    font-size: 12px;
  }

  .homePageContainer {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 20px;
    padding: 20px;
  }

  .mainContent {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .sideMenu {
    background-color: #f8f9fa;
    border-radius: 12px;
    padding: 20px;
  }
  
  .menuSection ul {
    list-style-type: none;
    padding: 0;
  }
  
  .menuSection li {
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .menuSection li:hover {
    background-color: #e0e0e0;
  }
  
  .contactSection {
    padding-top: 20px;
  }
  
  .contactItem {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }

  .descriptionBlock {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    padding: 30px;
    text-align: center;
}

.productsSection {
  background-color: #f8f9fa;
  border-radius: 12px;
  /* padding: 20px; */
}

.footer {
  background-color: #1a1a1a;
  color: #fff;
  padding: 40px 0 20px;
  margin-top: auto;
}

.footerContent {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  padding: 0 20px;
}

.footerSection h4 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 18px;
}

.footerSection ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footerSection ul li {
  margin-bottom: 10px;
}

.footerSection ul li a {
  color: #ccc;
  text-decoration: none;
  transition: color 0.3s;
}

.footerSection ul li a:hover {
  color: #fff;
}

.contactList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contactItem {
  display: flex;
  align-items: center;
  gap: 10px;
  /* color: #ccc; */
}

.socialLinks {
  display: flex;
  gap: 15px;
}

.socialIcon {
  color: #fff;
  transition: color 0.3s;
}

.socialIcon:hover {
  color: #3b97e8;

}

.footerBottom {
  max-width: 1200px;
  margin: 40px auto 0;
  padding: 20px;
  border-top: 1px solid #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ccc;
}

.footerLinks {
  display: flex;
  gap: 20px;
}

.footerLinks a {
  color: #ccc;
  text-decoration: none;
  transition: color 0.3s;
}

.footerLinks a:hover {
  color: #fff;
}


.aboutContainer {
  /* max-width: 1200px; */
  margin: 0 auto;
  padding: 20px;
}

.aboutHero {
  text-align: center;
  padding: 60px 0;
  background: linear-gradient(to right, #3b97e8, #5189dc);
  color: white;
  border-radius: 12px;
  margin-bottom: 40px;
}

.aboutSubtitle {
  font-size: 1.2rem;
  margin-top: 10px;
}

.aboutSection {
  margin-bottom: 40px;
}

.aboutSection h2 {
  color: #333;
  margin-bottom: 20px;
}

.advantagesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(max-content, 1fr));
  gap: 20px;
  margin-top: 30px;
}

.advantageCard {
  padding: 25px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  text-align: center;
  transition: transform 0.3s ease;
}

.advantageCard:hover {
  transform: translateY(-5px);
}

.advantageIcon {
  font-size: 40px !important;
  color: #3b97e8;
  margin-bottom: 15px;
}

.statsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 30px;
}

.statCard {
  text-align: center;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 12px;
}

.statCard h3 {
  color: #3b97e8;
  font-size: 2.3rem;
  margin-bottom: 10px;
}

.statCard p {
  font-size: 1.3rem;
  font-weight: 600;
  color: #666
}



.faqContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
}

.faqHeader {
  text-align: center;
  margin-bottom: 40px;
}

.faqHeader h1 {
  color: #333;
  margin-bottom: 10px;
}

.faqHeader p {
  color: #666;
  font-size: 1.1rem;
}

.faqCategory {
  margin-bottom: 30px;
}

.faqCategory h2 {
  color: #3b97e8;
  margin-bottom: 20px;
}

.faqAccordion {
  margin-bottom: 10px !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #e0e0e0 !important;
}

.faqQuestion {
  font-weight: 500 !important;
  color: #333;
}

.faqAnswer {
  color: #666;
  line-height: 1.6;
}


.contactsContainer {
  /* max-width: 1200px; */
  margin: 0 auto;
  padding: 40px 20px;
}

.contactsHeader {
  text-align: center;
  margin-bottom: 40px;
}

.contactsHeader h1 {
  color: #333;
  margin-bottom: 10px;
}

.contactsContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

.contactsInfo {
  padding: 20px;
}

.contactCard {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  margin-bottom: 25px;
  padding: 15px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.contactCard svg {
  color: #3b97e8;
  font-size: 24px;
}

.contactCard h3 {
  margin: 0 0 5px 0;
  color: #333;
}

.contactCard p {
  margin: 0;
  color: #666;
}

.socialLinks {
  display: flex;
  gap: 15px;
  margin-top: 30px;
}

.socialButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 20px;
  background: #f5f5f5;
  border-radius: 8px;
  text-decoration: none;
  color: #333;
  transition: background-color 0.3s;
}

.socialButton:hover {
  background: #e0e0e0;
}

.contactForm {
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.formGroup {
  margin-bottom: 20px;
}

.formGroup label {
  display: block;
  margin-bottom: 8px;
  color: #333;
  font-weight: 500;
}

.formGroup input,
.formGroup select,
.formGroup textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
}

.formGroup textarea {
  resize: vertical;
}

.submitButton {
  width: 100%;
  padding: 12px;
  background: #3b97e8;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submitButton:hover {
  background: #53a2e7;
}

.catalogContainer {
  padding: 20px;
  /* max-width: 1400px; */
  margin: 0 auto;
  position: relative;
  width: 90%;
}

.catalogHeader {
  display: flex;
  gap: 20px;
}

.searchBox {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px 20px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.searchBox input {
  border: none;
  outline: none;
  width: 100%;
  font-size: 16px;
}

.filterButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  background: white;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  transition: background-color 0.3s;
}

.filterButton:hover {
  background: #f5f5f5;
}

.filterDrawer {
  position: fixed;
  right: -100%;
  top: 0;
  height: 100vh;
  background: white;
  padding: 20px;
  box-shadow: -2px 0 8px rgba(0,0,0,0.1);
  transition: right 0.3s ease;
  z-index: 1000;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.filterDrawer::-webkit-scrollbar {
  display: none;
}

.filterDrawerOpen {
  right: 0;
}

@media (max-width: 768px) {
  .filterDrawer {
    width: 100%;
    max-width: 100vw;
  }
  body.filterOpen {
    overflow: hidden;
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 999;
  }
  .filterDrawerOpen {
    left: 0;
  }
}

.filterDrawerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.closeFilterButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 999;
}

.filterContent {
  margin-bottom: 30px;
}

.filterActions {
  position: sticky;
  bottom: 0;
  background: white;
  padding: 20px 0;
  display: flex;
  gap: 10px;
}

.applyFiltersButton,
.resetFiltersButton {
  flex: 1;
  padding: 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
}

.applyFiltersButton {
  background: #3b97e8;
  color: white;
}

.resetFiltersButton {
  background: #f5f5f5;
}

.activeFilters {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.filterTag {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  background: #f0f0f0;
  border-radius: 20px;
  font-size: 14px;
}

.filterTag button {
  background: none;
  border: none;
  padding: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.sortContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  
}

.sortSelect {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  background: white;
}

@media (max-width: 768px) {
  .filterDrawer {
    width: 100%;
    right: -100%;
  }

  .catalogHeader {
    flex-direction: column;
  }

  .sortContainer {
    align-self: end;
  }

}

.productsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (max-width: 768px) {
  .productsHeader {
    flex-direction: column;
    gap: 20px;
  }
}

.productsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
}


.productCard:hover {
  transform: translateY(-5px);
}

.productCard img {
  width: 100%;
  /* height: 200px; */
  object-fit: cover;
  border-radius: 8px;
  /* margin-bottom: 15px; */
}


.price {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.addToCartButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #3b97e8;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.amount {
  font-size: 24px;
  font-weight: 700;
  color: #333;
}

.addToCartButton:hover {
  background: #53a2e7;
}

.filterSection {
  margin-bottom: 30px;
}

.filterSection h3 {
  font-size: 18px;
  color: #333;
  margin-bottom: 16px;
}

.filterOptions {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.categoryOption {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  cursor: pointer;
  padding: 12px;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.categoryOption:hover {
  background-color: #f5f5f5;
}

.categoryOption input[type="radio"] {
  margin-top: 4px;
}

.categoryContent {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-left: 40px;
}

.categoryName {
  font-weight: 500;
  color: #333;
}

.categoryDescription {
  font-size: 14px;
  color: #666;
}

.categoryOption input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  aspect-ratio: 1;
  border: 2px solid #ddd;
  border-radius: 50%;
  outline: none;
  position: absolute;
}

.categoryOption input[type="radio"]:checked {
  border-color: #3b97e8;
}

.categoryOption input[type="radio"]:checked::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  aspect-ratio: 1;
  background-color: #3b97e8;
  border-radius: 50%;
}

.popularBadge {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff4d4d;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}

.productImageContainer {
  position: relative;
  width: 95%;
  /* height: 200px; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #f8f9fa; */
  border-radius: 8px 8px 0 0;
  padding: 0 0 0 5%;
}


.productImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}


.featuresList li {
  padding: 5px 0;
  font-size: 14px;
  color: #666;
  display: flex;
  align-items: center;
}

.featuresList li::before {
  content: "•";
  color: #3b97e8;
  font-weight: bold;
  margin-right: 8px;
}

.cartPageContainer {
  /* max-width: 1200px; */
  margin: 0 auto;
  padding: 20px;
}

.cartHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.cartHeader h1 {
  font-size: 28px;
  color: #333;
}

.clearCartButton {
  padding: 8px 16px;
  border: none;
  background: none;
  color: #666;
  cursor: pointer;
  transition: color 0.3s;
}

.clearCartButton:hover {
  color: #ff4d4d;
}

.cartContent {
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 30px;
}

.cartItems {
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.cartItem {
  display: grid;
  grid-template-columns: 100px 1fr auto auto 40px;
  gap: 20px;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #eee;
}

.cartItem:last-child {
  border-bottom: none;
}

.cartItemImage {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f9fa;
  border-radius: 8px;
}

.cartItemImage img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.cartItemInfo h3 {
  font-size: 16px;
  margin-bottom: 8px;
  color: #333;
}

.cartItemDescription {
  font-size: 14px;
  color: #666;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cartItemQuantity {
  font-size: 14px;
  color: #666;
}

.cartItemPrice {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.removeButton {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  transition: color 0.3s;
}

.removeButton:hover {
  color: #ff4d4d;
}

.cartSummary {
  background: white;
  border-radius: 12px;
  padding: 20px;
  height: fit-content;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.cartSummary h2 {
  font-size: 20px;
  margin-bottom: 20px;
  color: #333;
}

.summaryDetails {
  margin-bottom: 20px;
}

.summaryRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 16px;
  color: #666;
}

.checkoutButton {
  width: 100%;
  padding: 12px;
  background: #3b97e8;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.checkoutButton:hover {
  background: #53a2e7;
}

.continueShoppingButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px;
  background: #f8f9fa;
  color: #333;
  text-decoration: none;
  border-radius: 6px;
  transition: background-color 0.3s;
}

.continueShoppingButton:hover {
  background: #e9ecef;
}

.emptyCart {
  text-align: center;
  /* min-height: 50vh; */
  padding: 60px 20px;
}

.emptyCartIcon {
  font-size: 64px;
  color: #ccc;
  margin-bottom: 20px;
}

.emptyCart h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.emptyCart p {
  color: #666;
  margin-bottom: 30px;
}

.returnButton {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  background: #3b97e8;
  color: white;
  text-decoration: none;
  border-radius: 6px;
  transition: background-color 0.3s;
}

.returnButton:hover {
  background: #53a2e7;
}

@media (max-width: 768px) {
  .cartContent {
    grid-template-columns: 1fr;
  }

  .cartItem {
    grid-template-columns: 80px 1fr auto;
    gap: 15px;
  }

  .cartItemQuantity {
    grid-column: 2;
  }

  .cartItemPrice {
    grid-column: 3;
  }

  .removeButton {
    grid-column: 3;
    justify-self: end;
  }
}




.homeContainer {
  width: 100%;
}

.heroSection {
  background: linear-gradient(135deg, #3b97e8 20%, #4d9e51 100%);
  color: white;
  padding: 80px 20px;
  text-align: center;
}

.heroContent {
  max-width: 800px;
  margin: 0 auto;
}

.heroContent h1 {
  font-size: 48px;
  margin-bottom: 20px;
}

.heroContent p {
  font-size: 20px;
  margin-bottom: 30px;
}

.heroCatalogButton {
  display: inline-block;
  padding: 15px 40px;
  background: white;
  color: #3b97e8;
  text-decoration: none;
  border-radius: 30px;
  font-weight: 600;
  transition: transform 0.3s;
  font-size: 1.2rem;
}

.heroCatalogButton:hover {
  transform: translateY(-2px);
}

.categoriesSection {
  padding: 80px 20px;
  background: #f8f9fa;
}

.categoriesSection h2 {
  text-align: center;
  margin-bottom: 40px;
  font-size: 32px;
  color: #333;
}

.categoriesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.categoryCard {
  background: white;
  padding: 30px;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.categoryIcon {
  font-size: 48px !important;
  color: #3b97e8;
  margin-bottom: 20px;
}

.categoryCard h3 {
  margin-bottom: 15px;
  color: #333;
}

.categoryCard p {
  color: #666;
}

.advantagesSection {
  padding: 80px 20px;
  background: white;
}

.advantagesSection h2 {
  text-align: center;
  margin-bottom: 40px;
  font-size: 32px;
  color: #333;
}

.advantagesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.advantageCard {
  display: flex;
  gap: 20px;
  padding: 30px;
  background: #f8f9fa;
  border-radius: 12px;
}

.advantageIcon {
  color: #3b97e8;
  font-size: 40px;
}

.advantageContent h3 {
  margin-bottom: 10px;
  color: #333;
}

.advantageContent p {
  color: #666;
}

.statsSection {
  background: #3b97e8;
  padding: 60px 20px;
  color: white;
}

.statsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}


@media (max-width: 768px) {
  .heroContent h1 {
    font-size: 36px;
  }
  
  .heroContent p {
    font-size: 18px;
  }
  
  .advantageCard {
    flex-direction: column;
    text-align: center;
  }

  .catalogContainer {
    grid-template-columns: 1fr;
  }
  
  .catalogFilter {
    position: sticky;
    top: 0;
    z-index: 100;
  }

  .contactsContent {
    grid-template-columns: 1fr;
  }

  .advantagesGrid,
  .statsGrid {
    grid-template-columns: 1fr;
  }
  
  .aboutHero {
    padding: 40px 20px;
  }
  .productsGrid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

.header {
  background: white;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
}

.headerContent {
  max-width: 1400px;
  margin: 0 auto;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 24px;
  font-weight: 700;
  color: #3b97e8;
  text-decoration: none;
  z-index: 1001;
}

.navigation {
  display: flex;
  gap: 30px;
  align-items: center;
}

.navLink {
  text-decoration: none;
  color: #333;
  font-weight: 500;
  transition: color 0.3s;
}

.navLink:hover {
  color: #3b97e8;
}

.navLink.active {
  color: #3b97e8;
}

.cartButton {
  color: #333;
  text-decoration: none;
  display: flex;
  align-items: center;
  z-index: 1001;
}

.menuButton {
  display: none;
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
  padding: 5px;
  z-index: 1001;
}

@media (max-width: 1024px) {
  .navigation {
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .menuButton {
    display: block;
  }

  .navigation {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100vh;
    background: white;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    transition: left 0.3s ease;
  }

  .navigationOpen {
    left: 0;
  }

  .navLink {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .logo {
    font-size: 20px;
  }

  .headerContent {
    padding: 12px 16px;
  }
}

.benefitsSection {
  padding: 20px 0;
  overflow: hidden;
  position: relative;
}

.benefitsSection h2 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 32px;
    color: #333;
}

.benefitsScroll {
  width: 100%;
  overflow: hidden;
}

.benefitsTrack {
  display: flex;
  animation: scroll 40s linear infinite;
  padding: 20px 0;
}

.benefitCard {
  flex: 0 0 auto;
  width: 200px;
  margin: 0 15px;
  padding: 20px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  text-align: center;
  transition: transform 0.3s ease;
}

.benefitCard:hover {
  transform: translateY(-5px);
}

.benefitIcon {
  font-size: 32px !important;
  color: #3b97e8;
  margin-bottom: 10px;
}

.benefitCard p {
  margin: 0;
  color: #666;
  font-size: 14px;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-200px * 7 - 30px * 7));
  }
}

.benefitsSection::before,
.benefitsSection::after {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 100px;
  z-index: 2;
}

.benefitsSection::before {
  left: 0;
  background: linear-gradient(to right, #f8f9fa 0%, transparent 100%);
}

.benefitsSection::after {
  right: 0;
  background: linear-gradient(to left, #f8f9fa 0%, transparent 100%);
}

.benefitsTrack:hover {
  animation-play-state: paused;
}

@media (max-width: 768px) {
  .benefitCard {
    width: 160px;
    padding: 15px;
  }

  .benefitIcon {
    font-size: 28px !important;
  }

  .benefitCard p {
    font-size: 12px;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-160px * 7 - 30px * 7));
    }
  }
}



/* DELIVERY PAGE */

.deliveryContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
}

.deliveryHero {
  text-align: center;
  margin-bottom: 40px;
}

.deliveryHero h1 {
  color: #333;
  margin-bottom: 10px;
}

.deliveryHero p {
  color: #666;
  font-size: 1.1rem;
}

.deliveryContent {
  margin: 0 auto;
}

.deliverySection,
.paymentSection,
.processSection,
.deliveryFaqSection {
  margin-bottom: 40px;
}

.deliverySection h2,
.paymentSection h2,
.processSection h2,
.deliveryFaqSection h2 {
  color: #3b97e8;
  margin-bottom: 20px;
}

.deliveryInfo {
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  background: white;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  transition: background-color 0.3s;
}

.deliveryInfo:hover {
  background-color: #f5f5f5;
}

.deliveryIcon {
  color: #3b97e8;
  font-size: 24px !important;
  flex-shrink: 0;
}

.deliveryInfo h3 {
  margin: 0 0 10px 0;
  color: #333;
  font-size: 1rem;
  font-weight: 500;
}

.deliveryInfo p {
  margin: 0;
  color: #666;
  font-size: 0.9rem;
  line-height: 1.5;
}

.paymentMethods {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.paymentMethod {
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  background: white;
  display: flex;
  align-items: center;
  gap: 15px;
  transition: background-color 0.3s;
}

.paymentMethod:hover {
  background-color: #f5f5f5;
}

.methodIcon {
  color: #3b97e8;
  display: flex;
  align-items: center;
}

.methodInfo h3 {
  margin: 0;
  color: #333;
  font-size: 1rem;
  font-weight: 500;
}

.methodInfo p {
  margin: 5px 0 0;
  color: #666;
  font-size: 0.9rem;
}

.deliverySteps {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.deliveryStep {
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  background: white;
  display: flex;
  align-items: center;
  gap: 15px;
  transition: background-color 0.3s;
}

.deliveryStep:hover {
  background-color: #f5f5f5;
}

.stepNumber {
  width: 32px;
  height: 32px;
  background: #3b97e8;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  flex-shrink: 0;
}

.stepInfo h3 {
  margin: 0;
  color: #333;
  font-size: 1rem;
  font-weight: 500;
}

.stepInfo p {
  margin: 5px 0 0;
  color: #666;
  font-size: 0.9rem;
}

.faqList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.faqList > div {
  margin-bottom: 10px !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #e0e0e0 !important;
}

@media (max-width: 768px) {
  .deliveryContainer {
    padding: 20px;
  }
  
  .deliveryHero {
    margin-bottom: 30px;
  }

  .deliveryInfo {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 15px;
  }
  
  .deliveryIcon {
    margin-bottom: 10px;
  }
  
  .methodInfo h3,
  .stepInfo h3 {
    font-size: 0.95rem;
  }
  
  .methodInfo p,
  .stepInfo p {
    font-size: 0.85rem;
  }
}




@media (max-width: 768px) {
  .footer {
    padding: 30px 0 10px;
  }

  .footerContent {
    grid-template-columns: 1fr;
    gap: 25px;
    padding: 0 15px;
  }

  .footerSection {
    
    padding: 15px;
    border-radius: 8px;
  }

  .footerSection h4 {
    margin-bottom: 15px;
    font-size: 16px;
  }

  .footerSection ul li {
    margin-bottom: 8px;
  }

  .contactList {
    gap: 12px;
  }

  .socialLinks {
    justify-content: center;
    margin-top: 15px;
  }

  .footerBottom {
    flex-direction: column;
    text-align: center;
    gap: 15px;
    margin-top: 30px;
    padding: 15px;
  }

  .footerLinks {
    flex-direction: column;
    gap: 12px;
  }

  .footerLinks a {
    padding: 8px;
    
    border-radius: 6px;
  }
}








.notificationOverlay {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  min-width: 300px;
  max-width: 90%;
}

.notification {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
  border-radius: 8px !important;
}

@keyframes slideIn {
  from {
    transform: translateY(-100%) translateX(-50%);
    opacity: 0;
  }
  to {
    transform: translateY(0) translateX(-50%);
    opacity: 1;
  }
}

.notificationOverlay {
  animation: slideIn 0.3s ease-out;
}

@media (max-width: 768px) {
  .notificationOverlay {
    width: 90%;
    top: 10px;
  }
}

.footerButton {
  background: none;
  border: none;
  color: #ccc;
  padding: 0;
  font: inherit;
  cursor: pointer;
  transition: color 0.3s;
  text-align: left;
}

.footerButton:hover {
  color: #fff;
}


.customSoftwareContainer {
  width: 100%;
  padding: 0;
}

.customSoftwareHero {
  text-align: center;
  padding: 80px 20px;
  background: linear-gradient(135deg, #3b97e8 0%, #5189dc 100%);
  color: white;
  margin-bottom: 40px;
}

.customSoftwareHero h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.customSoftwareHero p {
  font-size: 1.2rem;
  opacity: 0.9;
  margin-bottom: 30px;
}

.scrollButton {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 15px 30px;
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid white;
  color: white;
  border-radius: 30px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.scrollButton:hover {
  background: white;
  color: #3b97e8;
  transform: translateY(-2px);
}

.scrollButton svg {
  font-size: 24px;
  transition: transform 0.3s ease;
}

.scrollButton:hover svg {
  transform: translateY(3px);
}

.customSoftwareContent {
  padding: 0 0px;
}

.introSection {
  text-align: center;
  max-width: 800px;
  margin: 0 auto 60px;
  padding: 0 20px;
}

.introSection h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.introSection p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #666;
}

.benefitsSectionCustom {
  width: 100%;
  padding: 40px 0;
  background: #f8f9fa;
  overflow: hidden;
  position: relative;
  margin: 40px 0;
}

.benefitsSectionCustom h2 {
  text-align: center;
  margin-bottom: 40px;
  font-size: 2rem;
  color: #333;
  padding: 0 20px;
}

.benefitsScroll {
  width: 100%;
  overflow: hidden;
}

.benefitsTrack {
  display: flex;
  animation: scroll 40s linear infinite;
  padding: 20px 0;
}

.benefitCard {
  flex: 0 0 auto;
  width: 300px;
  margin: 0 15px;
  padding: 25px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  text-align: center;
  transition: transform 0.3s ease;
}

.benefitCard:hover {
  transform: translateY(-5px);
}

.benefitIcon {
  color: #3b97e8;
  font-size: 32px;
  margin-bottom: 15px;
}

.benefitCard h3 {
  margin-bottom: 10px;
  color: #333;
  font-size: 18px;
}

.benefitCard p {
  color: #666;
  font-size: 14px;
  line-height: 1.5;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-300px * 6 - 30px * 6));
  }
}

.benefitsSectionCustom::before,
.benefitsSectionCustom::after {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 100px;
  z-index: 2;
}

.benefitsSectionCustom::before {
  left: 0;
  background: linear-gradient(to right, #f8f9fa 0%, transparent 100%);
}

.benefitsSectionCustom::after {
  right: 0;
  background: linear-gradient(to left, #f8f9fa 0%, transparent 100%);
}

.benefitsTrack:hover {
  animation-play-state: paused;
}

.stagesSection {
  padding: 60px 20px;
  margin: 60px auto;
  max-width: 1400px;
}

.stagesSection h2 {
  text-align: center;
  font-size: 2rem;
  color: #333;
  margin-bottom: 40px;
}

.stagesList {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.stageItem {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  padding: 25px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.stageNumber {
  width: 40px;
  height: 40px;
  background: #3b97e8;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  flex-shrink: 0;
}

.stageContent h3 {
  margin-bottom: 10px;
  color: #333;
  font-size: 18px;
}

.stageContent p {
  color: #666;
  line-height: 1.5;
}

.contactSection {
  width: 100%;
  padding: 60px 20px;
  background: #f8f9fa;
  margin-top: 60px;
  text-align: center;
}

.contactSection h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.contactSection p {
  color: #666;
  font-size: 1.1rem;
  margin-bottom: 30px;
}

.contactActions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.primaryButton {
  display: inline-block;
  padding: 15px 30px;
  background: #3b97e8;
  color: white;
  text-decoration: none;
  border-radius: 8px;
  font-weight: 500;
  transition: background-color 0.3s;
}

.primaryButton:hover {
  background: #2980b9;
}

.contactInfo {
  margin-top: 20px;
  color: #666;
}

.contactInfo p {
  margin: 10px 0;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .customSoftwareHero {
    padding: 40px 15px;
  }

  .customSoftwareHero h1 {
    font-size: 2rem;
  }

  .scrollButton {
    padding: 12px 24px;
    font-size: 1rem;
  }

  .customSoftwareContent {
    padding: 0 0px;
  }

  .benefitCard {
    width: 260px;
    padding: 20px;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-260px * 6 - 30px * 6));
    }
  }

  .stageItem {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
  }

  .contactSection {
    padding: 40px 15px;
  }
}

@media (max-width: 480px) {
  .customSoftwareHero h1 {
    font-size: 1.8rem;
  }

  .benefitCard {
    width: 240px;
    padding: 15px;
  }

  .stageNumber {
    width: 35px;
    height: 35px;
    font-size: 18px;
  }
}

@media (min-width: 1921px) {
  .stagesSection {
    max-width: 1600px;
  }
  
  .benefitsTrack {
    justify-content: center;
  }
}



.loaderContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.loaderLogo {
  font-size: 2rem;
  font-weight: bold;
  color: #3b97e8;
}

.loaderSpinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3b97e8;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



.addToCartButton {
  /* Keep existing styles */
  transition: all 0.3s ease;
}

.addToCartButtonAnimating {
  transform: scale(0.95);
  background-color: #4CAF50 !important; /* Green color */
}

.addToCartButtonInCart {
  background-color: #4CAF50 !important; /* Green color */
}

.buttonContent {
  display: flex;
  align-items: center;
  gap: 8px;
}

@keyframes popAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

.animatingProduct {
  animation: popAnimation 0.3s ease;
}

.headerActions {
    display: flex;
}

.clickable_div {
    cursor: pointer;
    user-select: none;
    transition: all 0.2s ease-in-out;
  }
  
  /* Состояние при наведении */
.clickable_div:hover {
    background-color: #f3f4f6;
    color: #2563eb;
  }
  
  /* Состояние активного нажатия */
.clickable_div:active {
    background-color: #e5e7eb;
    color: #1d4ed8;
    transform: scale(0.98); 
  }